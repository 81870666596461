import { type GetStaticProps } from 'next';

import { localizedContentService } from '../services';

import { HomeTemplate } from '../templates';

import { modifiers } from '../data/shared';

import {
  type IHomePageProps,
  type IHomeLocalizedContent,
} from '../modules/home/pageProps/@interfaces';

const Home = (props: IHomePageProps) => <HomeTemplate {...props} />;

const getStaticPropsHandler: GetStaticProps = async () => {
  const content = localizedContentService<IHomeLocalizedContent>(
    'pt-BR',
    ['home', 'footerContent', 'headerContent', 'mainServices'],
    [modifiers.STATIC_ASSETS_PATH, modifiers.CURRENT_YEAR]
  );

  return {
    props: {
      ...content.home,
      footerContent: content.footerContent,
      headerContent: content.headerContent,
      mainServices: content.mainServices,
    },
  };
};

export const getStaticProps = getStaticPropsHandler;

export default Home;
